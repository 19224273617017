.body-t{
width: 100%;
height: 100%;
}
.body-in{
margin: 0 110px 0px 110px;
padding: 0px 0px 0px 0px;
}
.banner-img{
    width: 100%;
}
.banner-container{
    text-align: center;
}
.sec-2{
    text-align: center;
    margin-top: 80px;
}
.header-pic-rotate{
    display: none;
}
.desc1{
    color: #c9ab81;
    margin-bottom: 13px;
    font-family: 'Tanha';
    font-size: 17px;
}
.titr1{
    color: #c9ab81;
    font-family: 'Vazir';
    font-size: 50px;
}
.decor-l{
    position: relative;
    display: inline-flex;
    left: -30px;
    margin-top: 12px;
}

.decor-r{
    position: relative;
    display: inline-flex;
    left: 30px;
    margin-top: 12px;
}

.menu-text{
    display: inline-flex;
    position: relative;
}
.titr2{
    color: #c9ab81;
    font-family: 'Vazir';
    font-size: 35px;
}
.menuleft-title{
    text-align: center;
    padding: 20px 0;
}
.menuleft{
    display: flex;
    margin-top: 140px;
}
.menuleft-des1{
    width: 50%;
    position: sticky;
    height:50%;
    top:25%;
    padding-bottom: 510px;

}

.menuleft-text{
    width: 50%;
}
.pricing-main{
    color: #c9ab81;
    display: inline-table;
float: right;
width: 100%;
padding: 8px 0;
display: flex;
align-items: center;
position: relative;
}
.pricing-item{
    position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    border-bottom: 3px solid #7A7A7A;
    cursor: pointer;
  }

.pricing-line{
    display: table-cell;
    width: 60%;
    border-bottom: 1px solid #715b3e;
    position: relative;
    bottom: 13px;   
}
.pricing-line::before{
    content: '';
    position: absolute;
    bottom: 5px;
    width: 100%;
    border-bottom: 1px solid #715b3e;
}
.pricing-price{
    font-weight: 700;
-ms-word-wrap: break-word;
word-wrap: break-word;
color: #c9ab81;
font-size: 19px;
line-height: 1.52em;
letter-spacing: .23em;
letter-spacing: .03em;
margin: 0;
padding-right: 15px;
font-family: 'Vazir';
background-color: #0b1315;
padding: 0 10px 0 0;
}

.pricing-title{
    font-family: 'Vazir';
    font-size: 22px;
    float: right;
    margin: 0;
    position: absolute;
    right: 0;
    background-color: #0b1315;
padding: 0 0 0 10px;
}
.details{
    font-family: 'Vazir';
background-color: #0b1315;
color: white;
direction: rtl;
}

.pricing-holder{
    margin: 0 70px;
}
.left-wrapper{
    margin: 10% 23.5% 0% 8%;
padding: 0px 0px 0px 0px;
align-content: center;
position: relative;
}
.left-img{
    width: 90%;
    position: absolute;
    z-index: 1;
    animation: popIn 1s slideInFromLeft;
}

.left-stroke{
    position: absolute;
    top:-4%;
    right:12%
}
@keyframes popIn {
    0%{
        clip-path: inset(0 0 100% 0);
    }
    100%{
        clip-path: inset(0 0 0 0);
    }
}
.foodPic{
    width: 100%;
display: block;
overflow: hidden;
max-height: 0px;
transition: all 0.5s cubic-bezier(0,1,0,1);
}

.foodPic.show{
    max-height: 9999px !important;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}
.more{
    color: white;
}
.header-pic{
    display: none;
}

.menu-nav{
    margin: 0 25px;
background-color: #c9ab81;
border: 0;
border-radius: 7px;
padding: 10px;
color: #0b1315;
font-family: 'vazir';
font-size: 13pt;
cursor: pointer;

}
.menu-buttons{
    margin: 70px 0;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .body-in {
      margin: auto;
      background-color: #013453;
    }
    .menuleft{
        display: block;
        margin-top: 0;
    }
    .menuleft-text{
        width: 100%;
        background-color: #013453;
        margin-top: -70px;
    }

    .menuleft-text-nomargin{
        margin-top: 0 !important;
    }

    .menuleft-des1{
    display: none;

    }
    .pricing-holder{
        margin: 0 15px;
    }
    .header-pic{
        width: 100%;
        display: block;
        margin-top: -14px;
        height: 304px;
        object-fit: cover;
        object-position: bottom;
    }
    body{
        margin: 0;
        background-color: #013453;
    }
    .pricing-main{
        background-color: #013453;
    }
    .pricing-title{
        background-color: #013453;
    }
    .pricing-price{
        background-color: #013453;
    }
    .details{
        background-color: #013453;
    }
    .sec-2{
        display: none;
    }
    .banner-img{
    display: none;}

    .pricing-item{
        margin-left: 0;
    }
    .menu-buttons{
        margin: -14px 26px 40px 26px;
        display: grid;
        z-index: 1;
    }
    .menu-nav{
        margin: 8px 25px;
        padding: 8px 71px;;
    }
    .header-logo{
        background-color: #013453;
    }
    .header-pic-rotate{
        width: 100%;
        display: block;
        rotate: 180deg;
        margin-top: -14px;
        height: 213px;
        object-fit: cover;
        object-position: bottom;

    }

  }
@font-face {
    font-family: 'Tanha';
    src: local('Tanha'),url(./fonts/Tanha.ttf);
}
@font-face {
    font-family: 'Vazir';
    src: local('Vazir'),url(./fonts/Vazir.ttf);
}
body{
    background-color: #0b1315;
}
.header-logo{
    width: 100%;
    margin: 0;
padding: 40px;
z-index: 110;
box-sizing: border-box;
text-align: center;
}
.logo{
    width:100px;
}
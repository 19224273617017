.waiter{
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 75px;
    height: 75px;
    cursor: pointer;
    z-index: 1;

}
  .waiter:hover {
animation: shake 0.5s;
animation-iteration-count: 5;
}

@keyframes shake {
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
}

#myBtn{
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #dbae46;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
      }